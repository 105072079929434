import { Box, Stack, Text, Title, useMantineTheme, Loader, Paper, Timeline, Group } from '@mantine/core';
import { formatDate, getReferenceString } from '@medplum/core';
import { Patient, DocumentReference } from '@medplum/fhirtypes';
import { formatDistanceToNow } from 'date-fns';
import { useMedplum } from '@medplum/react';
import { IconCalendar, IconChevronRight, IconFileDescription } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

export function FollowUps({ limit, searchQuery }: { limit?: number; searchQuery?: string; }): JSX.Element {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const medplum = useMedplum();
  const [followUps, setFollowUps] = useState<DocumentReference[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);


  useEffect(() => {
    const fetchFollowUps = async () => {
      try {
        const patient = medplum.getProfile() as Patient;
        if (!patient || !patient.id) {
          throw new Error('Patient profile not found');
        }

        const searchQuery = `subject=${getReferenceString(patient)}&type=follow-up&_sort=-_lastUpdated`;
        console.log('Search query:', searchQuery); // Debug log

        const result = await medplum.searchResources('DocumentReference', searchQuery);
        console.log('API response:', result); // Debug log

        setFollowUps(result);
      } catch (err) {
        console.error('Error details:', err);
        setError(err instanceof Error ? err.message : 'An unknown error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchFollowUps();
  }, [medplum]);

  // Filter follow-ups based on search query
  const filteredFollowUps = followUps.filter((followUp) => {
    if (!searchQuery) return true;
    
    const searchLower = searchQuery.toLowerCase();
    
    // Search through title
    const title = followUp.content?.[0]?.attachment?.title?.toLowerCase() || '';
    if (title.includes(searchLower)) return true;
    
    // Search through date if needed
    const date = followUp.date ? formatDate(followUp.date) : '';
    if (date.toLowerCase().includes(searchLower)) return true;

    // Add more fields to search through if needed
    
    return false;
  });

  const displayedFollowUps = limit ? filteredFollowUps.slice(0, limit) : filteredFollowUps;
  const hasMore = limit ? filteredFollowUps.length > limit : false;


  if (loading) {
    return (
      <Box p="xl">
        <Loader />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p="xl">
        <Text color="red">Error: {error}</Text>
      </Box>
    );
  }

  return (
    <Box>
      <Paper shadow="xs" p="md" withBorder>
        <Stack gap="md">
          <Group justify='apart'>
          <Text size="xl" fw={700}>Re-Calls</Text>
            {hasMore && (
              <Text component={Link} to="/followups" size="sm" c="blue">
                View All ({followUps.length})
              </Text>
            )}
          </Group>
          {loading ? (
            <Loader />
          ) : (
            followUps.length === 0 ? (
              <Text>No re-calls found.</Text>
            ) : (
              <Timeline active={-1} bulletSize={24}>
                {displayedFollowUps.map((followUp) => (
                  <Timeline.Item
                    key={followUp.id}
                    bullet={<IconFileDescription size={16} />}
                    title={
                      <Text fw={500}>
                        {followUp.content[0].attachment.title || 'No description'}
                      </Text>
                    }
                    onClick={() => navigate(`/cf/${followUp.id}`)}
                    style={{ cursor: 'pointer' }}
                  >
                    <Text size="sm" c="dimmed" style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                      <IconCalendar size={14} />
                      <time>
                        {followUp.date ? formatDistanceToNow(new Date(followUp.date)) + ' ago' : 'No date'}
                      </time>
                    </Text>
                  </Timeline.Item>
                ))}
              </Timeline>
            )
          )}
        </Stack>
      </Paper>
    </Box>
  );
}