import ReactPixel from 'react-facebook-pixel';
import { useLocation } from 'react-router-dom';

// Initialize Facebook Pixel
const initializeFacebookPixel = () => {
  ReactPixel.init('2718682785186473');
};

// Call initialization
initializeFacebookPixel();

const INTAKE_PATHS = ['/profile', '/profile-bp', '/profile-heart', '/profile-bf'];

export function useFacebookPixel() {
  const location = useLocation();

  const isIntakePath = INTAKE_PATHS.some(path => 
    location.pathname.startsWith(path)
  );

  const trackFunnelStep = (step: string, data?: Record<string, any>) => {
    // Only track if we're in an intake funnel
    if (!isIntakePath) {
      return;
    }

    ReactPixel.trackCustom(step, {
      content_name: step,
      ...data
    });
  };

  // For critical conversion points
  const trackFunnelComplete = (data?: Record<string, any>) => {
    if (!isIntakePath) {
      return;
    }

    ReactPixel.track('CompleteRegistration', {
      ...data
    });
  };

  return { trackFunnelStep, trackFunnelComplete };
}