// src/common/googleTagManager.ts
import { useCallback, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

// GTM ID (Google Ads Conversion ID)
const GTM_ID = 'AW-16708276166';

// Google Ads Conversion Label
const CONVERSION_LABEL = 'wYRyCMi7yNQZEMafkJ8-';

// Initialize GTM
const initializeGTM = () => {
  console.log('Initializing GTM with ID:', GTM_ID);
  
  // Initialize with only supported options
  TagManager.initialize({
    gtmId: GTM_ID,
    dataLayerName: 'dataLayer',
    // We cannot use customScriptSrc or events properties as they're not supported
  });
};

// Call initialization
initializeGTM();

// Same intake paths as Facebook Pixel for consistency
const INTAKE_PATHS = ['/profile', '/profile-bp', '/profile-heart', '/profile-bf'];

export function useGoogleTagManager() {
  const location = useLocation();

  // Determine if the current location is an intake path
  const isIntakePath = useCallback(() => {
    return INTAKE_PATHS.some(path => location.pathname.startsWith(path));
  }, [location.pathname]);

  const trackSurveyStep = useCallback((stepId: string, questionText: string, data?: Record<string, any>) => {
    if (!isIntakePath()) {
      console.log("GTM: Not an intake path, skipping trackSurveyStep");
      return;
    }

    try {
      console.log("GTM: Tracking surveyStep", { stepId, questionText, data });
      // Use dataLayer push instead of TagManager.dataLayer for better CSP compatibility
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'surveyStep',
        surveyName: 'Health Profile',
        stepId: stepId,
        questionText: questionText,
        ...(data || {})
      });
    } catch (error) {
      console.error('Error tracking GTM survey step:', error);
    }
  }, [isIntakePath]);

  const trackLeadGeneration = useCallback((data?: Record<string, any>) => {
    if (!isIntakePath()) {
      console.log("GTM: Not an intake path, skipping trackLeadGeneration");
      return;
    }

    try {
      console.log("GTM: Tracking leadGenerated (Google Ads)", { data });
      // Use dataLayer push directly for better CSP compatibility
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'conversion',
        send_to: `${GTM_ID}/${CONVERSION_LABEL}`,
        value: 1.0,
        currency: 'USD',
        ...(data || {})
      });
    } catch (error) {
      console.error('Error tracking GTM lead event:', error);
    }
  }, [isIntakePath]);

  // For completed registrations/conversions
  const trackCompletion = useCallback((data?: Record<string, any>) => {
    if (!isIntakePath()) {
      console.log("GTM: Not an intake path, skipping trackCompletion");
      return;
    }

    try {
      console.log("GTM: Tracking conversionComplete", { data });
      // Use dataLayer push directly
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'conversionComplete',
        ...(data || {})
      });
    } catch (error) {
      console.error('Error tracking GTM completion event:', error);
    }
  }, [isIntakePath]);

  return {
    trackSurveyStep,
    trackLeadGeneration,
    trackCompletion
  };
}

// Add global type definition for dataLayer
declare global {
  interface Window {
    dataLayer: any[];
  }
}