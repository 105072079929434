// pages/followups/AllFollowUps.tsx
import { Box, TextInput, Stack, Paper, Container } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { useState } from 'react';
import { FollowUps } from './FollowUps';

export function AllFollowUps(): JSX.Element {
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <Container size="lg" py={10}>
    <Box>
      <Paper shadow="xs" p="md" mb="md" withBorder>
        <TextInput
          placeholder="Search follow-ups..."
          leftSection={<IconSearch size={16} />}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.currentTarget.value)}
        />
      </Paper>
      <FollowUps searchQuery={searchQuery} />
    </Box>
    </Container>
  );
}